<template>
  <div>
    <div class="title">
      <i class="el-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="content" @click="projectListShow = false">
      <el-upload
        class="upload"
        :action="uploadAction"
        :on-success="handleUploadSuccess"
        multiple
        :show-file-list="false"
      >
        <i class="el-icon-camera"></i>
        <div class="el-upload__text">上传图片</div>
      </el-upload>
      <div class="images">
        <div class="img" v-for="(item, index) in images" :key="index">
          <img :src="item.fullurl" alt="" />
          <i class="el-icon-circle-close" @click="removeImage(index)"></i>
        </div>
        <div class="img"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="button" @click="confirmSend">确认发送</div>
    </div>
    <div class="project-list" v-show="projectListShow">
      <div
        class="item"
        :class="project_id == item.id ? 'selected' : ''"
        v-for="item in projectList"
        :key="item.id"
        @click="project_id = item.id"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      uploadAction: "",
      projectListShow: false,
      project_id: 0,
      projectList: [],
      images: [],
      img_type: 0,
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
  },
  methods: {
    confirmSend() {
      if (!this.projectListShow) {
        this.projectListShow = true;
        let data = {
          userType: "painter",
          is_communication: true,
          is_drawing_type: false,
        };
        this.$api.project.phase_list(data).then((res) => {
          this.projectList = res.data;
          if (!res.data.length) {
            this.$message("无项目，请接项目后重试！");
            this.projectListShow = false;
          }
        });
        return;
      }
      let images = [];
      this.images.forEach((item) => {
        images.push(item.url);
      });
      let data = {
        project_id: this.project_id,
        images: images.join(","),
        img_type: this.$route.query.img_type,
      };
      this.$api.mobile_project_images.add(data).then(() => {
        this.$router.go(-1);
      });
    },
    handleUploadSuccess(res) {
      this.images.push(res.data);
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
  },
};
</script>

<style>
.el-upload {
  padding: 65px 0;
  width: 100%;
}
</style>
<style lang="less" scoped>
.title {
  text-align: left;
  font-size: 25px;
  padding: 30px 25px;
  color: #ffffff;
}
.content {
  padding: 0 25px;
  .upload {
    background: #f6f6f9;
    border-radius: 2px;
    .el-upload__text {
      margin-top: 16px;
    }
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;
    .img {
      width: 29%;
      position: relative;
      padding: 7px 7px 0 0;
      img {
        width: 100%;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 0;
        right: 0;
        color: #ff1d1d;
        font-size: 17px;
      }
    }
  }
}
.bottom {
  position: fixed;
  bottom: 26px;
  width: 100%;
  .button {
    width: 243px;
    background: #0083ff;
    box-shadow: 0px 2px 8px 0px rgba(0, 131, 255, 0.44);
    border-radius: 110px;
    padding: 15px 0;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    line-height: 12px;
    margin: 0 auto;
  }
}

.project-list {
  background: #ffffff;
  box-shadow: 0px 0px 11px 0px rgba(31, 139, 225, 0.2);
  border-radius: 22px;
  width: calc(100% - 50px);
  position: fixed;
  bottom: 100px;
  margin: 0 25px;
  max-height: 289px;
  overflow: auto;
  padding: 0 19px;
  box-sizing: border-box;
  .item {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 28px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  .selected {
    font-weight: bold;
    color: #0083ff;
  }
}
</style>